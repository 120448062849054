<template>
  <div id="edit-payment-method">
    <HeaderNavigation
      :title="`${isEditMode ? 'modifier' : 'ajouter'} un moyen de paiement`"
    />
    <b-container class="main-container">
      <b-row>
        <b-col>
          <h1>
            <button class="close" @click="$router.back()">
              <span>&times;</span>
            </button>
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <header>
            Le paiement à Evenir est effectué par le service sécurisé Stripe de
            façon mensuelle à la fin du mois en cours.
          </header>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <CardBrands />
        </b-col>
      </b-row>
      <b-form class="card-form">
        <IdCardIcon class="card-icon" />
        <b-form-row>
          <b-col>
            <b-form-group label="NUMERO DE CARTE">
              <card-number
                v-if="!isEditMode"
                ref="cardNumber"
                id="card-number"
                :stripe="stripeKey"
                :options="options"
                @change="cardDetails.number = $event.complete"
              />
              <b-form-input v-else v-model="last4" readonly />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group label="DATE D'EXPIRATION">
              <card-expiry
                v-if="!isEditMode"
                ref="cardExpiry"
                id="card-expiry"
                :stripe="stripeKey"
                :options="options"
                @change="cardDetails.expiry = $event.complete"
              />
              <b-input-group v-else>
                <b-form-input v-model="expMonth" placeholder="MM" />
                <b-form-input v-model="expYear" placeholder="YY" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="CVC">
              <card-cvc
                v-if="!isEditMode"
                ref="cardCvc"
                id="card-cvc"
                :stripe="stripeKey"
                :options="options"
                @change="cardDetails.cvc = $event.complete"
              />
              <b-input v-else value="XXX" readonly />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group label="NOM DU TITULAIRE DE LA CARTE">
              <b-form-input v-model="owner" placeholder="JOHN DOE" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="text-right">
            <b-button
              v-loader.disable="validating"
              class="validate"
              variant="dark"
              :disabled="!complete"
              @click="onValidate"
            >
              VALIDER
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
      <b-row v-if="isEditMode" class="mt-3">
        <b-col class="text-center">
          <b-button
            v-loader.disable="deleting"
            class="delete"
            variant="warning"
            @click="onDeleteCard"
          >
            Supprimer cette carte</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import CardBrands from '@/components/svg/CardBrands.vue'
import IdCardIcon from '@/components/svg/IdCardIcon.vue'
import { CardNumber, CardExpiry, CardCvc } from 'vue-stripe-elements-plus'
import { Routes } from '@/router.js'

export default {
  name: 'edit-payment-method',
  components: {
    HeaderNavigation,
    CardBrands,
    IdCardIcon,
    CardNumber,
    CardExpiry,
    CardCvc
  },
  props: { id: { type: String } },
  data() {
    return {
      cardDetails: {
        number: false,
        expiry: false,
        cvc: false,
        owner: undefined
      },
      stripeKey: process.env.VUE_APP_STRIPE_API_PublicKey,
      options: {
        style: {
          base: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 400,
            fontSize: '14px'
          }
        }
      },
      validating: false,
      deleting: false
    }
  },
  computed: {
    isEditMode() {
      return !!this.id
    },
    complete() {
      return !this.isEditMode
        ? this.cardDetails.number &&
            this.cardDetails.expiry &&
            this.cardDetails.cvc
        : !!this.expMonth && !!this.expYear
    },
    card() {
      return (
        this.$store.getters.getCreditCard(this.id) || {
          billing_details: {},
          card: {
            last4: 'XXXX'
          }
        }
      )
    },
    last4() {
      return `XXXX XXXX XXXX ${this.card.card.last4}`
    },
    expMonth: {
      get() {
        return this.card.card.exp_month
      },
      set(value) {
        this.updateCreditCardDetails({
          cardId: this.id,
          expDate: { month: value, year: this.expYear },
          owner: this.owner
        })
      }
    },
    expYear: {
      get() {
        return this.card.card.exp_year
      },
      set(value) {
        this.updateCreditCardDetails({
          cardId: this.id,
          expDate: { month: this.expMonth, year: value },
          owner: this.owner
        })
      }
    },
    owner: {
      get() {
        return this.isEditMode
          ? this.card.billing_details.name
          : this.cardDetails.owner
      },
      set(value) {
        if (this.isEditMode) {
          this.updateCreditCardDetails({
            cardId: this.id,
            expDate: {
              month: this.expMonth,
              year: this.expYear
            },
            owner: value
          })
        } else {
          this.cardDetails.owner = value
        }
      }
    }
  },
  mounted() {
    if (this.isEditMode) {
      this.loadCreditCard({ api: this.$apiService, id: this.id })
        .then(pm => (this.pm = pm))
        .catch(err =>
          console.error(
            `Uncaught error while loading credit card [${this.id}]: `,
            err
          )
        )
    }
  },
  methods: {
    ...mapMutations(['updateCreditCardDetails']),
    ...mapActions([
      'createCreditCard',
      'updateCreditCard',
      'loadCreditCard',
      'deleteCreditCard'
    ]),
    onValidate() {
      this.validating = true

      const prom = !this.isEditMode
        ? this.createCreditCard({
            api: this.$apiService,
            pm: {
              card: this.$refs.cardNumber.$refs.element._element,
              billing_details: { name: this.owner }
            }
          })
        : this.updateCreditCard({
            api: this.$apiService,
            pm: this.card
          })

      prom
        .then(() => this.$router.push(Routes.ACCOUNT))
        .catch(err => console.error('Something went wrong: ', err))
        .finally(() => (this.validating = false))
    },
    onDeleteCard() {
      this.deleting = true

      this.deleteCreditCard({
        api: this.$apiService,
        id: this.id
      })
        .then(() => this.$router.push(Routes.ACCOUNT))
        .catch(err =>
          console.error('Uncaught error while deleteing charging point: ', err)
        )
        .finally(() => (this.deleting = false))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#edit-payment-method {
  height: 100vh;
  background-color: #f8f8f8;

  h1 {
    position: relative;
    margin: 0;
    font-size: 16px;
    line-height: 16px;

    button.close {
      position: absolute;
      right: 0;
      font-weight: $font-weight-regular;
      font-size: 2em;
      line-height: unset;
      color: $dark;
      opacity: 1;
      z-index: 1;
    }
  }

  header {
    margin-bottom: 26.8px;
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 18px;
    color: $text-light;
  }

  .card-form {
    margin-top: 26.8px;
    border-radius: 10px;
    padding: 15px;
    background-color: #fff;

    svg.card-icon {
      fill: $dark;
      stroke: $dark;
      margin-bottom: 20px;
    }

    .form-control {
      padding-left: 0;
    }
  }

  .validate {
    height: 48px;
    width: 142px;
  }
}
</style>
<style lang="scss">
@import '@/styles.scss';

#edit-payment-method {
  .card-form {
    .col-form-label {
      margin-bottom: 15px;
      font-weight: $font-weight-bold;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.65px;
    }

    .StripeElement {
      border: none;
      border-bottom: 1px solid black;
      border-radius: 0;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 14px;
      padding: 0.375rem 0.75rem;
      padding-left: 0;
    }
  }
}
</style>
